<script setup>
import _ from 'lodash';
import { computed, ref, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import IconBase from '../icon/IconBase.vue';
import IconCamera from '../icon/IconCamera.vue';
import IconFolder from '../icon/IconFolder.vue';
import IconPlaylist from '../icon/IconPlaylist.vue';
import IconFilm from '../icon/IconFilm.vue';
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';
import IconChevronUpDown from '../icon/IconChevronUpDown.vue';
import StreamsWidget from './StreamsWidget.vue';
import { useMatchMedia } from '@/utils'

const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
  selectedStream: {
    type: Object,
    required: false
  },
  dropRight: {
    type: Boolean,
    required: false,
    default: false
  },
  showPlaylist: {
    type: Boolean,
    default: false
  },
  hideNotification: {
    type: Boolean,
    default: false
  },
  closeAfterSelect: {
    type: Boolean,
    default: false
  },
  onlyLiveSelector: {
    type: Boolean,
    default: false
  },
  refLink: {
    type: Number,
    default: null,
    required: false
  },
  dropdownDisabled: {
    type: Boolean,
    default: false
  },
  source: {
    type: String,
    default: 'paywall'
  }
})

const emit = defineEmits({
  'on-show': (value) => { return value },
  'on-hide': (value) => { return value }
})

const selectedSourceIcon = computed(() => {
  if (props.selectedStream?.type === 'live') return IconCamera
  if (props.selectedStream?.type === 'vod') return IconFolder
  if (props.source === 'dynamic' && props.selectedStream?.bytes) return IconFilm
  if (props.selectedStream?.streams) return IconPlaylist
  return
})

const isDesktop = useMatchMedia('(min-width: 768px)');

const sourceDropdown = ref(null)

const selectedVodCheckbox = ref(false)
const sourceSelectedName = ref('Select source')

const onStreamSourceSelected = (stream, type) => {
  sourceSelectedName.value = stream.name
  if (sourceDropdown.value) sourceDropdown.value.toggleDropdown()
  if (props.refLink || props.refLink === 0) {
    stream.refLink = props.refLink
  }
  emit('on-source-selected', stream)
  selectedVodCheckbox.value = false
}

//Hooks
watch(() => props.selectedStream, () => {
  if (props.selectedStream?.fileName) sourceSelectedName.value = props.selectedStream?.fileName
  if (props.selectedStream?.name) sourceSelectedName.value = props.selectedStream?.name
  if (!props.selectedStream) sourceSelectedName.value = 'Select source'
}, { immediate: true })
</script>
<template>
  <div class="flex justify-between items-center w-full">
    <drop-down-menu :disabled="dropdownDisabled" v-if="isDesktop" ref="sourceDropdown" class="w-full"
      customClasses="w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize truncate"
      fullWidth="true" position="right" preventHide maxHeight="max-h-auto">
      <template #toggle-button>
        <icon-base class="!mr-2 shrink-0">
          <component :is="selectedSourceIcon" />
        </icon-base>
        <span class="truncate">{{ sourceSelectedName }}</span>
        <icon-base class="!ml-auto shrink-0">
          <icon-chevron-up-down />
        </icon-base>
      </template>
      <template #menu-items>
        <drop-down-menu-item isCustom class="!px-3 !pb-3">
          <!-- TODO Stream Widget should always have wrapper ID and placement prop = body!
           This might have big problems if not exist. We need to refctore it better in the future -->
          <div id="#streams-widget-wrapper">
            <StreamsWidget placement="body" @add-stream="onStreamSourceSelected"
              :multiview="(refLink || refLink === 0) ? true : false" :only-live="onlyLiveSelector"
              :source="props.source" />
          </div>
        </drop-down-menu-item>
      </template>
    </drop-down-menu>
    <div v-else id="#streams-widget-wrapper" class="flex items-center w-full">
      <icon-base class="!mr-2 shrink-0">
        <component :is="selectedSourceIcon" />
      </icon-base>
      <span class="truncate mr-auto">{{ sourceSelectedName }}</span>
      <StreamsWidget placement="body" @add-stream="onStreamSourceSelected"
        :multiview="(refLink || refLink === 0) ? true : false" :only-live="onlyLiveSelector"
        :hide-notification="hideNotification" :close-after-select="closeAfterSelect" :source="props.source" />
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>