<script setup>
import { computed, onMounted, ref, getCurrentInstance, watch } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import _ from 'lodash';
import Vue from 'vue';
import Spinner from "@/components/ui/Spinner.vue"
import AdvertisingService from '@/services/AdvertisingService';
import VideosUploaderCompactWidget from '@/components/ui/VideosUploaderCompactWidget.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import { bytes } from '@/filtersNew'
import { useStore } from 'vuex'
import FormButton from '../../components/Atoms/FormButton.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronUpDown from '../../components/icon/IconChevronUpDown.vue';
import Badge from '../../components/Badge.vue';
import IconBin from '../../components/icon/IconBin.vue';

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const props = defineProps({
	stream: {
		type: Object,
		required: true,
		validator(v) {
			return Object.prototype.hasOwnProperty.call(v, '_id');
		}
	},
	streamAlive: {
		type: Boolean
	}
})

const adSourceDropdown = ref(null)
const adTypeDropdown = ref(null)
const adSkipDropdown = ref(null)
const adPlacementDropdown = ref(null)
const advVideoUploadWidget = ref(null)

const processing = ref(false)
const saveProcessing = ref(false)
const showSaveButton = ref(false)
const enabled = ref(false)

const adSource = ref([
	{
		text: 'Use a custom ad server URL',
		value: 'custom_url'
	},
	{
		text: 'Upload video(mp4)',
		value: 'custom_video'
	},
])
const adType = ref([
	{
		text: 'VAST',
		value: 'vast'
	}
])
const adPlacement = ref([
	{
		text: 'Pre-roll (beginning of stream)',
		value: 'pre'
	}
])
const adSkipAfter = ref([
	{
		text: '1s',
		value: 1,
	},
	{
		text: '3s',
		value: 3,
	},
	{
		text: '5s',
		value: 5,
	}
])

const videoMeta = ref(null)
const form = ref({
	source: 'custom_video',
	type: 'vast',
	placement: 'pre',
	skippable: false,
	skipAfter: 1,
	clickUrl: null,
	manifestUrl: null
})
const toggleBtnDisabled = ref(false)
const ignoreFormChange = ref(false)
const isRestricted = ref(false)

const setSource = (source) => {
	form.value.source = source
	adSourceDropdown.value.toggleDropdown()
}
const setType = (source) => {
	adTypeDropdown.value.toggleDropdown()
}
const setSkipAfter = (skipInterval) => {
	form.value.skipAfter = skipInterval
	adSkipDropdown.value.toggleDropdown()
}
const setAdPlacement = () => {
	adPlacementDropdown.value.toggleDropdown()
}
const videoFiles = computed(() => {
	if (videoMeta.value) {
		// hide upload button
		return [{ fake: 1 }];
	}
	return [];
})
const adSourceText = computed(() => {
	if (form.value.source === 'custom_video') {
		return 'Upload Video(mp4)'
	} else if (form.value.source === 'custom_url') {
		return 'Use a custom ad server URL'
	}
})

watch(
	() => form,
	() => {
		if (ignoreFormChange.value) {
			return;
		}
		showSaveButton.value = true
		toggleBtnDisabled.value = true
	},
	{ deep: true }
)

onMounted(async () => {
	await setupAd();
	showSaveButton.value = false;
	toggleBtnDisabled.value = false;

	const restrictedPlans = [
		'6434ffbd40771d569f1e98e6', // Starter Monthly
		'6435032f40771d569f1e98f2', // Starter Yearly
		// '6435007440771d569f1e98ea', // Professional Monthly
		// '643503a640771d569f1e98f6', // Professional Yearly
		// '6435001640771d569f1e98e8', // Standard Monthly
		// '6435035f40771d569f1e98f4', // Standard Yearly
		'6362114709950a6a642540c3', // All in one Trial
		'5e8db3dcc3db0533322db725', // VOD Trial
	]
	const subscriptions = _.cloneDeep(store.state.User.subscriptions);
	isRestricted.value = subscriptions.some((sub) => {
		if (!sub.category) sub.category = 'restream'
		if (props.stream.type === 'vod') {
			const aioSub = _.find(subscriptions, i => i.category === 'live')
			// const vodSub = _.find(subscriptions, i => i.category === 'vod')
			if (aioSub) {
				return restrictedPlans.indexOf(aioSub.package._id) !== -1
			}
		}
		return restrictedPlans.indexOf(sub.package._id) !== -1 && props.stream.type === sub.category
	})
})

const onVideoAdded = (item) => {
	videoMeta.value = item;
}

const uploadInspected = (item) => {
	const duration = _.get(item, ['upload', 'mediaInfo', 'duration'], 0);
	if (duration > 120) {
		root.$emit('bv::show::modal', 'alert-video-exceed-duration');
		advVideoUploadWidget.value.removeVideoFile(item);
		store.dispatch('Ui/dropStreamToUpload')
		store.dispatch('Ui/dropFilesToUpload')
	}
}

const resetSettings = () => {
	ignoreFormChange.value = true;
	form.value = {
		text: 'Upload video(mp4)',
		source: 'custom_url',
		type: 'vast',
		placement: 'pre',
		skippable: false,
		skipAfter: 1,
		clickUrl: null,
		manifestUrl: null
	};

	Vue.nextTick(() => {
		ignoreFormChange.value = false;
	})
	updateAd();
	if (enabled.value) {
		toggleAd();
	}
	if (videoMeta.value) {
		removeAdMedia();
	}
}

const removeAdMedia = async () => {
	try {
		await AdvertisingService.updateStreamAd(props.stream._id, { videoMeta: null });
		videoMeta.value = null;
		advVideoUploadWidget.value.showUploadButton = true;

		notify({
			group: 'success',
			text: 'Media file removed'
		});
	} catch (err) {
		notify({
			group: 'error',
			text: err.message || 'Advertising media remove failed'
		});
	}
}

const updateAd = async () => {
	try {
		saveProcessing.value = true;
		await AdvertisingService.updateStreamAd(props.stream._id, form.value);

		notify({
			group: 'success',
			text: 'Advertising options saved'
		});

		showSaveButton.value = false;
		toggleBtnDisabled.value = false;
	} catch (err) {
		notify({
			group: 'error',
			text: err.message || 'Advertising options update failed'
		});
	}

	saveProcessing.value = false;
}

const toggleAd = async () => {
	try {
		await AdvertisingService.toggleStreamAd(props.stream._id);
		enabled.value = !enabled.value;
	} catch (err) {
		notify({
			group: 'error',
			text: err.message || 'Advertising toggle failed'
		});
	}
}
const setupAd = async () => {
	processing.value = true;
	try {
		const advObj = await AdvertisingService.getStreamAd(props.stream._id);
		const formData = _.pick(advObj, ['source', 'type', 'placemnet',
			'clickUrl', 'skippable', 'manifestUrl', 'skipAfter'])

		enabled.value = advObj.enabled;
		videoMeta.value = advObj.videoMeta;
		if (videoMeta.value?.mediaInfo) {
			const codecs = mediaCodecsParser(videoMeta.value.mediaInfo)
			videoMeta.value.mediaInfo.codecs = codecs
		}
		_.assign(form.value, formData);
	} catch (err) {
		if (err.toString().includes('ad not found')) {
			processing.value = false;
			return
		}

		notify({
			group: 'error',
			text: err.message || 'get stream advertising failed'
		});
	}
	processing.value = false;
}
const showInBytes = (item) => {
	return bytes(item, false, 0, true)
}

const mediaCodecsParser = (item) => {
	const codecs = []
	item.track.forEach(track => {
		if (track['@type'] === 'Video') codecs.push({ type: 'video', codec: track.Format })
		if (track['@type'] === 'Audio') codecs.push({ type: 'audio', codec: track.Format })
	})
return codecs
}
</script>

<template>
	<div>
		<div class="flex" v-if="isRestricted">
			<div class="text-center">
				<p class="text-lg !mt-6 !mb-2">You don’t have access to this feature</p>
				<p class="text-sm text-surface-8"><router-link to="/subscribe?category=live"
						class="hover:no-underline">Upgrade</router-link> or <FormButton type="link"
						customClasses="text-surface-8 underline" onclick="Intercom('show')">contact us</FormButton> to
					access this feature</p>
				<p class="text-s-lg mt-5 mb-1 color-light">You don’t have access to this feature</p>
			</div>
		</div>
		<div v-else>
			<Spinner v-if="processing" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)"
				spinner-size="15px" />
			<section v-else>
				<p class="text-surface-8 text-base mb-4">
					Place a Video Ad in your {{ stream.type === 'live' ? 'livestream' : 'vod stream' }} easily. <a target="_blank"
						class="text-reset text-underline"
						href="https://docs.castr.com/en/articles/5453948-in-stream-advertising-introduction">Learn more
						here</a> or <a target="_blank" class="text-reset text-underline"
						href="https://www.youtube.com/watch?v=e7AmRZ6SqzE&ab_channel=Castr">Watch the tutorial</a>
				</p>
				<h3 class="text-xl font-medium !mb-2">Ad source</h3>
				<DropDownMenu class="!mb-4" ref="adSourceDropdown" fullWidth
					customClasses="w-full bg-surface-3 rounded-md flex justify-between items-center h-8 text-tiny !px-4">
					<template #toggle-button>
						{{ adSourceText }}
						<icon-base>
							<icon-chevron-up-down />
						</icon-base>
					</template>
					<template #menu-items>
						<DropDownMenuItem v-for="(adItem, index) in adSource" :key="index" @click="setSource(adItem.value)">
							{{ adItem.text }}
						</DropDownMenuItem>
						<DropDownMenuItem>
							<span class="text-surface-6">Upload image</span>
							<Badge class="ml-2">Coming
								soon</Badge>
						</DropDownMenuItem>
					</template>
				</DropDownMenu>
				<div v-if="form.source === 'custom_url'">
					<h3 class="text-xl font-medium !mb-2">Ad type</h3>
					<DropDownMenu class="!mb-4" ref="adTypeDropdown" fullWidth
						customClasses="w-full bg-surface-3 rounded-md flex justify-between items-center h-8 !px-4 text-tiny uppercase">
						<template #toggle-button>
							{{ form.type }}
							<icon-base>
								<icon-chevron-up-down />
							</icon-base>
						</template>
						<template #menu-items>
							<DropDownMenuItem class="uppercase" v-for="(adItem, index) in adType" @click="setType" :key="index">
								{{ adItem.text }}
							</DropDownMenuItem>
							<DropDownMenuItem>
								<span class="text-surface-6">VPAID</span>
								<Badge class="ml-2">Coming
									soon</Badge>
							</DropDownMenuItem>
							<DropDownMenuItem>
								<span class="text-surface-6">VMAP</span>
								<Badge class="ml-2">Coming
									soon</Badge>
							</DropDownMenuItem>
							<DropDownMenuItem>
								<span class="text-surface-6">IMA</span>
								<Badge class="ml-2">Coming
									soon</Badge>
							</DropDownMenuItem>
						</template>
					</DropDownMenu>
					<FormInput label="Server URL" v-model="form.manifestUrl" id="price"
						placeholder="https://example.com/manifest/preroll.xml"></FormInput>
				</div>
				<div class="custom-video-wrapper" v-if="form.source === 'custom_video'">
					<videos-uploader-compact-widget ref="advVideoUploadWidget" :stream="stream" :multiple-uploads="false"
						:mp4-only="true" :video-files="videoFiles" :total-files-per-stream="1" :advertising-media-upload="true"
						@video-added="onVideoAdded" @upload-inspected="uploadInspected" />
					<div v-if="videoMeta">
						<div class="flex items-center" v-if="!videoMeta.mediaInfo">
							{{ videoMeta.fileName }}
							<Spinner class="mx-auto" />
						</div>
						<ul class="" v-else>
							<li class="flex">
								<div>
									<p :title="videoMeta.fileNameOriginal">{{ videoMeta.fileName }}</p>
									<p class="text-surface-7 text-sm">
										<span> {{ videoMeta.mediaInfo.width ?
											videoMeta.mediaInfo.width : videoMeta.mediaInfo.track[1].Width }} x {{
												videoMeta.mediaInfo.height ?
													videoMeta.mediaInfo.height : videoMeta.mediaInfo.track[1].Height }}</span>
										<span v-if="videoMeta.mediaInfo.duration > 60"> - {{ videoMeta.mediaInfo.durationMins
											}}
											mins</span>
										<span v-else> - {{ videoMeta.mediaInfo.duration }} secs</span>
										<span> - {{ showInBytes(videoMeta.bytes) }}</span>
									</p>
									<div class="flex flex-wrap gap-x-1">
										<Badge v-for="(track, index) in videoMeta.mediaInfo.codecs" :key="index" class="!inline-flex" :class="track.type">
										{{ track.codec }}
									</Badge>
									</div>
								</div>
								<FormButton class="ml-auto" size="sm" isIcon type="danger" @click="removeAdMedia"
									v-show="!videoMeta.statusProcessing">
									<icon-base>
										<icon-bin />
									</icon-base>
								</FormButton>
							</li>
						</ul>
					</div>
					<hr class="!my-4">
				</div>
				<div v-if="form.source === 'custom_video'">
					<h3 class="text-xl font-medium !mb-2">Ad Settings</h3>
						<div class="flex justify-between">
							<label class="text-lg">Skippable ad</label>
							<FormSwitch v-model="form.skippable" />
						</div>
					<label v-if="form.skippable">Viewers can skip ad after</label>
					<DropDownMenu  v-if="form.skippable" class="!mb-4" ref="adSkipDropdown" fullWidth
						customClasses="w-full bg-surface-3 rounded-md flex justify-between items-center text-tiny h-8 !px-4">
						<template #toggle-button>
							{{ form.skipAfter }}s
							<icon-base>
								<icon-chevron-up-down />
							</icon-base>
						</template>
						<template #menu-items>
							<DropDownMenuItem class="uppercase" v-for="(adSkipItem, index) in adSkipAfter" :key="index" @click="setSkipAfter(adSkipItem.value)">
								{{ adSkipItem.text }}
							</DropDownMenuItem>
						</template>
					</DropDownMenu>
					<hr class="!my-4">
				</div>
				<div v-if="form.source === 'custom_video'">
					<label>Ad placement</label>
					<DropDownMenu class="!mb-4" ref="adPlacementDropdown" fullWidth
						customClasses="w-full bg-surface-3 rounded-md flex justify-between items-center h-8 text-tiny !px-4 ">
						<template #toggle-button>
							{{ adPlacement[0].text }}
							<icon-base>
								<icon-chevron-up-down />
							</icon-base>
						</template>
						<template #menu-items>
							<DropDownMenuItem v-for="(adPlacementItem, index) in adPlacement" :key="index" @click="setAdPlacement">
								{{ adPlacementItem.text }}
							</DropDownMenuItem>
							<DropDownMenuItem>
								<span class="text-surface-6">Post-roll (end of stream)</span>
								<Badge class="ml-2">Coming
									soon</Badge>
							</DropDownMenuItem>
							<DropDownMenuItem>
								<span class="text-surface-6">Set time</span>
								<Badge class="ml-2">Coming
									soon</Badge>
							</DropDownMenuItem>
						</template>
					</DropDownMenu>
					<FormInput label="Click-through URL" v-model="form.clickUrl" id="price" size="md" placeholder="http://example.com/test?a=1" />
				</div>
				<div class="flex gap-x-2 !mt-4">
					<FormButton v-if="showSaveButton" @click="updateAd">
						{{ saveProcessing ? 'Saving' : 'Save' }}
					</FormButton>
					<FormButton v-if="!enabled"
						:disabled="toggleBtnDisabled || (!enabled && !videoMeta && form.source === 'custom_video')"
						@click="toggleAd" type="submit" size="md" variant="primary">
						Enable Ad
					</FormButton>
					<FormButton v-if="enabled" :disabled="toggleBtnDisabled" @click="toggleAd"
						type="secondary">
						Disable Ad
					</FormButton>
					<FormButton @click="resetSettings" type="secondary">
						Reset Ad
					</FormButton>
				</div>
			</section>
		</div>
		<alert-modal modal-id="alert-video-exceed-duration" message="Video duration must be less then 2 minutes"
			ok-text="Got it" />
	</div>
</template>
